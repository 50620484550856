/* src/components/Header/Header.module.scss */

.header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #000;
    color: #fff;
    border-bottom: 1px solid #3C4142;

  
    .logo {
      a {
        color: #fff;
        text-decoration: none;
        font-size: 1.5rem;
        font-weight: bold;
  
      }
    }
  
    .nav {
      &.hidden {
        display: none;
      }
  
      &.open {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 60px;
        right: 0;
        background-color: #000;
        padding: 1rem;
        border-radius: 8px;
        width: 100%;
        max-width: 200px;
        z-index: 10;
      }
  
      @media (min-width: 768px) {
        display: flex !important;
        flex-direction: row; /* Display anchors in a row on desktop */
        position: static;
        background-color: transparent;
        gap: 2rem;
        width: auto;
        padding: 0;
      }
    }
  
    .navList {
      list-style: none;
      padding-left: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;
  
      @media (min-width: 768px) {
        flex-direction: row; /* Display anchors in a row on desktop */
        gap: 2rem;
      }
  
      li {
        a {
          display: block;
          color: #fff;
          text-decoration: none;
          font-weight: bold;
          padding: 0.5rem 0;
        }
      }
    }
  
    .mobileMenuIcon {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 18px;
      cursor: pointer;
  
      .bar {
        width: 25px;
        height: 3px;
        background-color: #fff;
        margin-bottom: 4px;
  
        &:last-child {
          margin-bottom: 0;
        }
      }
  
      @media (min-width: 768px) {
        display: none;
      }
    }
  }
  